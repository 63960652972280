import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Header.css';
import logo from '../../assests/logo.jpg';
import {Helmet} from "react-helmet";

const Header = () => {
  return (
    <div className='header'>  
      <Helmet>
        <meta charset="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js" integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r" crossOrigin="anonymous"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.min.js" integrity="sha384-fbbOQedDUMZZ5KreZpsbe1LCZPVmfTnH7ois6mU1QK+m14rQ1l2bGBq41eYeM/fS" crossOrigin="anonymous"></script>    
        </Helmet> 
        
        <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-light bg-white fixed-top">
          <div className="container-fluid" id = 'headercontainer'>
          <a className = 'navbar-brand' href = "/">
              <img src = {logo} alt = 'logo' height = {100} width = {100}/>
            </a>
            <a className = 'navbar-brand' href = "/">
            <h5 className='mscncheadingdesktop'>M S CNC MANUFACTURE PTE. LTD.</h5> 
            <h5 className='mscncheadingmobile'>M S CNC <br/> 
            MANUFACTURE <br/> PTE. LTD.</h5>        
            </a>       
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>              
    
    <div className="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo03">
    
    <div className="header-items">
      
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link" aria-current="page" href="/">Home</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/our-profile" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            About Us
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/our-profile">Our Profile</a></li>
            <li><a className="dropdown-item" href="/our-beliefs">Our Beliefs</a></li>
            <li><a className="dropdown-item" href="/our-industry">Our Industry</a></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/pms" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Services
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/pms">Precision Machining Solutions</a></li>
            <li><a className="dropdown-item" href="/emm">Exotic Materials Machining</a></li>
          </ul>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="/occ" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Facilities
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="/occ">Our CAD/CAM Capabilities</a></li>
            <li><a className="dropdown-item" href="/opl">Our Production Layout</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/newsandevents">News and Events</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/careers">Careers</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/contactus">Contact Us</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="/qanda">Q & A</a>
        </li>
      </ul>
    </div>
    </div>
  </div>
</nav> 
      
      </div> 
      
    
  )
}

export default Header