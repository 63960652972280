import './Occ.css';
import banner from '../../assests/banner.jpg'
import React from 'react'
import interview from '../../assests/CADpicture.jpg';

const occ = () => {
  return (
    <div className='pmsmain'>
    <div className='pms'>
        <img src = {banner} alt = 'banner' className='banner' />       
    </div>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Our CAD/CAM Capabilities</h1>
     </div>
     <div className='pmsdescriptionbody'>
      <p className='pmsdescription'><em>Our CAD/CAM Capabilities</em></p>
     </div>
     <div className='grid-containerocc'>
     <div className='cadpicture'>
      <img src = {interview} alt = 'interview' className='interviewpicture' />
     </div>
     <div className='caddescription'>
      <p>M S CNC presently uses a high-performance, geometry-oriented CAD/CAM software. Equipped with Siemens Unigraphics,
        we can have direct interfaces to system based on CATIA, Unigraphics, Parasolid as well as standard data exchange formats
        such as DXF, DWG, IGES, SAT, VDA, STL and STEP. We are also able to handle the most precise of machining needs up to 3 + 2 
        axes simultaneous machining.
      </p>
     </div>
     </div>     
     </div>
  )
}

export default occ