import React from 'react';
import './Footer.css';
import iso from '../../assests/CMMlogo.jpg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone, faFax, faEnvelope, faClock, faMapLocationDot} from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare, faYoutube, faInstagram} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footerleft'>
        <h1>Contact Us</h1>
        <div className='phonecontainer'>
        <FontAwesomeIcon icon={faPhone} className = 'phoneicon' />
        <p className='phone'>Tel: <a href = 'tel:+6563699640'>+65 6369 9640</a></p>
        </div>
        <div className='faxcontainer'>
        <FontAwesomeIcon icon ={faFax} className = 'faxicon'/>
        <p className='fax'>Fax: +65 6369 9642</p> 
        </div>
        <div className='emailcontainer'>
        <FontAwesomeIcon icon ={faEnvelope} className = 'emailicon'/>
        <p className='email'>Email: <a href = 'mailto:mscnc@singnet.com.sg'>mscnc@singnet.com.sg</a></p> 
        </div>
        <div className='clockcontainer'>
        <FontAwesomeIcon icon ={faClock} className = 'clockicon'/>
        <p className='clock'>Mon-Sat 8:30AM - 6:30PM</p> 
        </div>    
        <div className='socialmediacontainer'>      
        <div className='facebookcontainer'>
          <a href = "https://www.facebook.com/mscncmanufacture" className='facebookiconlink' target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookSquare} className = 'facebookicon' size='3x'/>
        </a>
        </div>
        <div className='youtubecontainer'>
          <a href = "https://www.youtube.com/watch?v=ebUG-rFK0KE&ab_channel=MaYuanxin" className='youtubeiconlink' target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon = {faYoutube} className ='youtubeicon' size ='3x'/>
          </a>
        </div>
        <div className='instagramcontainer'>
          <a href='https://www.instagram.com/mscncmanufacture/' className='instagramiconlink' target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon icon ={faInstagram} className = 'instagramicon' size ='3x'/>
          </a>
        </div>
        </div>  
        </div>
        <div className='footermiddle'>
        <div className='locationcontainer'>
        <FontAwesomeIcon icon ={faMapLocationDot} className = 'locationicon'/>
        <p className='location'>5 Yishun Industrial Street 1<br></br>
        #04-13/14, Northspring Bizhub<br></br>
        Singapore 768161</p> 
        </div>   
        <a href = 'https://goo.gl/maps/sp5GaZVBkcUwaQun8'>
        <button type="button" className="btn btn-primary btn-sm" id = 'locationbutton'>
         Get Directions</button>
         </a>
        </div>
        <div className='isocontainer'>
          <img src = {iso} alt = 'isologo' className='isopicture'/>
          </div>
    </div>
  )
}

export default Footer