import './Ourindustry.css';
import React from 'react'
import banner from '../../assests/About-Us-banner-gold-2.jpg';
import airplane from '../../assests/airplane.jpg';
import satellite from '../../assests/satelite.jpg';
import automotive from '../../assests/Automotive.jpg';
import oil from '../../assests/oilandgas.jpg';
import biomedical from '../../assests/biomedical.jpg';
import { Helmet } from 'react-helmet';
const Ourindustry = () => {
  return (
    <div className='ourindustrymain'>
      <Helmet>
        <title>Our Industry</title>
        <meta name="description" content="CNC manufacturing manufacture industry customers industries" />
    </Helmet>
    <div className='ourprofile'>
        <img src = {banner} alt = 'banner' className='banner' />       
    </div>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Our Industry</h1>
     </div>
     <div className='ourindustrydescriptionbody'>
        <p className = 'ourindustrydescription'><em>Our extensive experience and knowledge of over 20 years makes us industry veterans, enabling us
            to provide world class engineering solutions to a wide variety of industries, which include the satellites, biomedical, oil & gas and aerospace
            industries from all over the world.</em></p>
     </div>
     <div className='grid-container'>
     <div className='airplane'>
        <img src = {airplane} alt = "airplane" className='airplanepicture' />
        <figcaption className='airplanepicturename'>Commercial Aerospace</figcaption>
        <img src = {satellite} alt = 'satellite' className='satellitepicture'/>
        <figcaption className='satellitepicturename'>Satellite Industry</figcaption>
        <img src = {biomedical} alt = 'biomedical' className='biomedicalpicture' />
        <figcaption className='biomedicalpicturename'>Biomedical Industry</figcaption>
     </div>
     <div className='ourindustry2'>
        <img src = {automotive} alt = 'automotive' className='automotivepicture' />
        <figcaption className='automotivename'>Automotive Industry</figcaption>
        <img src = {oil} alt = 'oil' className='oilpicture' />
        <figcaption className='oilname'>Oil & Gas Industry</figcaption>
     </div>
     </div>
     </div>
  )
}

export default Ourindustry