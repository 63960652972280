import '../Contactus/Contactus.css';
import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPhone, faFax, faEnvelope, faClock, faMapLocationDot} from '@fortawesome/free-solid-svg-icons';
const Success = () => {
  return (
    <div className='pmsmain'>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Contact Us</h1>
     </div>
     <div className='quoteheading'>
      <h1>Form Submitted Successfully!</h1>
      <p>We will get in touch within 3 working days. For urgent matters,
        please visit our workshop. 
      </p>
     </div>
     <div className='officelocation'>
      <h1 className='officeheading'>Office/Factory</h1>
      <div className='locationcontainercs'>
        <FontAwesomeIcon icon ={faMapLocationDot} className = 'locationiconcs'/>
        <p className='locationcs'>5 Yishun Industrial Street 1<br></br>
        #04-13/14, Northspring Bizhub<br></br>
        Singapore 768161</p> 
        </div> 
        <div className='phonecontainercs'>
        <FontAwesomeIcon icon={faPhone} className = 'phoneiconcs' />
        <p className='phonecs'>Tel: +65 6369 9640</p>
        </div>
        <div className='emailcontainercs'>
        <FontAwesomeIcon icon ={faEnvelope} className = 'emailiconcs'/>
        <p className='emailcs'>Email: mscnc@singnet.com.sg</p> 
        </div>
        <div className='clockcontainercs'>
        <FontAwesomeIcon icon ={faClock} className = 'clockiconcs'/>
        <p className='clockcs'>Mon-Sat 8:30AM - 6:30PM</p> 
        </div>
        <div className='faxcontainercs'>
        <FontAwesomeIcon icon ={faFax} className = 'faxiconcs'/>
        <p className='faxcs'>Fax: +65 6369 9642</p> 
        </div>
        
     </div>
  
     <div className = 'mapDiv'>
     <iframe title = "this is maps" width="600" height="450" style={{border:0}} loading="lazy" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJdzcNP2AU2jERH0I9xX8pm-U&key=AIzaSyBQjQ-aEWOaWdil73WfjXVMzNriDEPfcnQ"></iframe>
     </div> 
     </div>
  )
}


export default Success