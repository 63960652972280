import './Careers.css';
import React from 'react'
const Careers = () => {
  return (
    <div className='pmsmain'>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Careers</h1>
     </div>     
     <div className='careers'>
     <h1 className='careersheading'>Singaporean/Singapore PR are welcome to apply</h1>
     <h2 className='careersdescription'>Interested applicants please send in your CV/resume to mscnc@singnet.com.sg</h2>
     <h2>Current Openings</h2>
     <h3>1. CNC Machinist</h3>
     <p>Job Description:</p>
      <ul>• Set-up and operate CNC milling or Turning (ability to operate grinding and drilling machines will be advantage)</ul>
      <ul>• Perform machining works as per drawings and instructions</ul>
      <ul>• Understand Fanuc and perform simple CNC programming</ul>
      <ul>• Perform troubleshooting and minor maintenance of machines</ul>
      <ul>• Perform housekeeping activities on work area</ul>
      <ul>• Selects the appropriate tools, machines and materials to be used in preparation of machinery work</ul>
      <ul>• Perform quality check on finished products using precision instruments such as micrometers</ul>
      <ul>• Deliver output according to requirement</ul>
      <ul>• Any other ad-hoc duties as assigned by the superior</ul>
      <p>Competency Requirement:</p>
      <ul>• ITE / NTC / Diploma in Precision Machinery or Manufacturing industry</ul>
      <ul>• Experience in operating CNC Milling, Lathe Machines, grinding and drilling machines</ul>
      <ul>• Good knowledge of machining processes in Fanuc control</ul>
      <ul>• Proficient in MS, AutoCAD and Unigraphics (UG) software will be advantage</ul>
      <ul>• Able to interpret technical drawing and specifications</ul>
      <ul>• Able to multi-task and work well under pressure and work independently</ul>
      <h3>2. CNC Programmer</h3>
      <p>Job Description:</p>
      <ul>• Prepare CNC programs into all machining operations.</ul>
      <ul>• Perform tool path simulation</ul>
      <ul>• Troubleshoot programming related issues</ul>
      <p>Competency Requirement:</p>
      <ul>• Hands-on experiences in CNC programming.</ul>
      <ul>• Knowledge in Siemens Unigraphics software is a must.</ul>
      </div>
      </div>
  )
}

export default Careers