import React from 'react';
import './pms.css';
import banner from '../../assests/banner.jpg';
import cncpicture from '../../assests/IMG_2689.jpg';
import cmm1 from '../../assests/IMG_2741.jpg';
import cmm2 from '../../assests/IMG_2740.jpg';
import laserengraving from '../../assests/IMG_2742.jpg';
import wirecut from '../../assests/IMG_2688.jpg';

const pms = () => {
  return (
    <div className='pmsmain'>
    <div className='pms'>
        <img src = {banner} alt = 'banner' className='banner' />       
    </div>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Precision Machining Solutions</h1>
     </div>
     <div className='pmsdescriptionbody'>
      <p className='pmsdescription'><em>Our precision machining solutions include: </em></p>
     </div>
     <div className='grid-containerpms'>
     <div className='machinelistbody'>
      <ul className='machinelist'>
        <li className='machinelisting'>1. CNC 3 + 2 Axis</li>
        <li className='machinelisting'>2. CNC 3 Axis</li>
        <li className='machinelisting'>3. CNC Wire-Cut</li>
        <li className='machinelisting'>4. Laser Engraving</li>
      </ul>
     </div>     
     <div className='pmspicture'>
      <img src = {cncpicture} alt = 'cncpicture' className='cncpicture'/>
     </div>
     <div className='cmm1'>
        <img src = {cmm1} alt = 'cmm1' className='cmm1picture'/>
        <caption>CMM&nbsp;Machine</caption>
     </div>
     <div className='cmm2'>
        <img src = {cmm2} alt = 'cmm2' className='cmm2picture' />
        <caption>CMM&nbsp;Machine</caption>
     </div>
     <div className='laserengraving'>
      <img src = {laserengraving} alt = 'laserengraving' className='laserengravingpicture' />
      <caption>Laser&nbsp;Engraving</caption>
     </div>
     <div className='wirecut1'>
      <img src = {wirecut} alt = 'wirecut' className='wirecut1picture' />
      <caption>Wirecut&nbsp;Machine</caption>
     </div>
     </div>
    </div>
  )
}

export default pms
