import React from 'react';
import './Home.css';
import cad from '../assests/Header.png';
import cnc3 from '../assests/CNC3.jpeg';
import cnc8 from '../assests/CNC8.jpeg';
import partsfabrication from '../assests/IMG_0572.jpg';
import electrodemilling from '../assests/IMG_0581.jpg';
import moldinsert from '../assests/image013.jpg';
import cmm from '../assests/IMG_2740.jpg';
import wirecut from '../assests/IMG_2688.jpg';
import sample1 from '../assests/image009.jpg';
import sample2 from '../assests/image006.jpg';
import sample4 from '../assests/image013.jpg';
import sample5 from '../assests/IMG_0577.jpg';
import sample6 from '../assests/sample6.jpg';
import sample7 from '../assests/sample7.jpg';
import sample8 from '../assests/sample8.jpg';
import sample9 from '../assests/sample9.jpg';

import {Link} from 'react-router-dom';

const Home = () => {

    
  return (
    <div className='containerhome'>
    <div className='imagecontainer'>
      <img src = {cad} alt = 'cad' className='cadimage'/>
      <h2 className='texttitle'>WE MAKE<br></br>IDEAS HAPPEN</h2>
      <p className='imagedescription'>
        M S CNC offers comprehensive product development services from
        Parts, Jigs, Prototyping to 3D CNC milling for mold insert and electrode.
        Our customer oriented approach ensures quality products and competitive pricing.</p>
        <Link to = "/contactus" className='getaquote'>Get A Quote</Link>
        
    </div>   
    <div className='middle'>
    <h1 className='ourservices' >Our Services</h1>
    <div className='grid-containerourservices'>
    <div className='parts'>
          <img src ={partsfabrication} alt='parts' className='partspicture'></img>
          <div className = 'partsbutton'>
            <Link to='/pms' className='partsbutton1'>Learn&nbsp;More</Link>
          </div>
          <p className='partsdescription'>Parts Fabrication</p>
          </div>
          <div className='electrodemilling'>
          <img src = {electrodemilling} alt = 'electrodemil' className='electrodepicture'></img>
          <div className = 'electrodebutton'>
            <Link to = '/pms' className = 'electrodebutton1'>Learn&nbsp;More</Link>
          </div>
          <p className='electrodedescription'>Electrode&nbsp;Milling</p>
          </div>
          <div className='moldinsert'>
          <img src = {moldinsert} alt = 'moldinsertpicture' className='moldinsertpicture'></img>
          <div className = 'moldbutton'>
            <Link to = '/pms' className='moldbutton1'>Learn&nbsp;More</Link>
          </div>
          <p className='molddescription'>Mold&nbsp;Insert&nbsp;Milling</p>       
        </div>  
        </div>
        </div>
        <div className='ourfacilitiesblock'>
        <div className='ourfacilities'>
          <h1 className='ourfacilitiesheading'>Our Facilities</h1>
          <div className='grid-containerourfacilities'>
          <div>
          <img src = {cnc3} alt = 'cnc3' className='cnc3picture' />
          <h1 className='cnc32heading'>CNC Machines 3 + 2 Axis</h1>
          <p className='cnc32'>We have 2 CNC machines that are 5 axis capable of the most<br></br>demanding and complex projects.</p>
          </div>
          <div className='cnc8'>
          <img src = {cnc8} alt ='cnc8' className='cnc8picture' />
          <h1>CNC Machines 3 Axis</h1>
          <p>We have 6 CNC machines capable of most projects.</p>
          </div>  
          <div className='cmm'>
            <img src = {cmm} alt = 'cmm' className='cmmpicture' />
            <h1 className='cmmheading'>CMM Machines</h1>
            <p className='cmmdescription'>We have 2 CMM machines capable of high precision measurement.<br></br>
            All products have to pass the vigorous inspection before delivery.</p>
          </div>
          <div className='wirecut'>
            <img src = {wirecut} alt = 'wirecut' className='wirecutpicture' />
            <h1 className='wirecutheading'>Wirecut Machine</h1>
            <p className='wirecutdescription'>We have 1 wirecut machine capapble of cutting to the most specific needs.</p>
          </div>
          </div>
          </div>
          </div>
          <div className= 'samples'>
            <h1 className='samplesheading'>Sample Parts</h1>
            <div className='grid-containersamples'>
            <div className='sample1'>
            <img src = {sample1} alt = 'sample1' className='sample1picture'/>
            <p className='sample1description'>Material:Al6061<br></br>
            CNC Milling With Sandblast</p>
            </div>
            <div className='sample2'>
              <img src = {sample2} alt = 'sample2' className='sample2picture' />
              <p className='sample2description'>Material:Al6061<br></br>
            CNC Milling</p>
            </div>
            <div className='sample3'>
            <img src = {partsfabrication} alt ='sample3' className='sample3picture' />
            <p className='sample3description'>Material:Al6061<br></br>
            CNC Turning + CNC Milling</p>
          </div>
          <div className='sample4'>
            <img src ={sample4} alt ='sample4' className='sample4picture'/>
            <p className='sample4description'>Mold Insert Milling<br></br>
            Material 8407 HRC52</p>
          </div>
          <div className='sample5'>
            <img src = {sample5} alt = 'sample5' className='sample5picture' />
            <p className='sample5description'>Material: Plastic<br></br>
            CNC Milling</p>
          </div>
          <div className='sample6'>
            <img src = {electrodemilling} alt = 'sample6' className='sample6picture' />
            <p className='sample6description'>Material: Copper<br></br>
            Electrode Milling</p>
          </div>
          <div className='sample7'>
            <img src = {sample7} alt = 'sample6' className='sample7picture' />
            <p className='sample7description'>Material: Al6061<br></br>
            CNC Milling</p>
          </div>
          <div className='sample8'>
            <img src = {sample8} alt = 'sample6' className='sample8picture' />
            <p className='sample8description'>Material: Al6061<br></br>
            CNC Milling with black powder coating</p>
          </div>
          <div className='sample9'>
            <img src = {sample9} alt = 'sample6' className='sample9picture' />
            <p className='sample9description'>Material: Al6061<br></br>
            CNC Milling with black powder coating</p>
          </div>
          <div className='sample10'>
            <img src = {sample6} alt = 'sample6' className='sample10picture' />
            <p className='sample6description'>Material: Al6061<br></br>
            CNC Milling</p>
          </div>
          </div>
          </div>
          

    </div>
   
    
  )

  
}

export default Home