import './App.css';
import Home from './Components/Home';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Ourprofile from './Components/our-profile/Ourprofile';
import Ourbeliefs from './Components/our-beliefs/Ourbeliefs';
import Ourindustry from './Components/our-industry/Ourindustry';
import Pms from './Components/pms/pms';
import ScrollToTop from './Components/ScrollToTop';
import Emm from './Components/Exotic emm/emm';
import Occ from './Components/Our cad Cam/Occ';
import Opl from './Components/Our Production Layout/Opl';
import Newsandevents from './Components/News And Events/Newsandevents';
import Contactus from './Components/Contactus/Contactus';
import Careers from './Components/Careers/Careers';
import Success from './Components/Success/Success'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Helmet} from "react-helmet";
import React from 'react';
import QandA from './Components/QandA/QandA';
import MetaTags from 'react-meta-tags';
import NotFound from './Components/NotFound/NotFound';

function App() {
  return (
    
    <Router>   
        <Helmet>
        <title>CNC Precision Engineering Parts Singapore | CNC Milling</title>
        <meta name="description" content="M S CNC Manufacture Pte. Ltd. has over 
        20 years of experience in CNC machining parts and CNC Milling. Machine Shop in Singapore. 
        Quality you can trust" />
    </Helmet>
       <div className='heading'>      
      <Header />
    </ div >
    <ScrollToTop>
      <Switch>
        <Route exact path ="/">  
    <Home />    
    </Route>
    <Route exact path = '/our-profile'>
      <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC</title>
        <meta name="description" content="M S CNC offers Precision engineering. Our promise of quality is the top priority.
        Customer care, competitive pricing, handling complex difficult projects is what we do. " />
        <link rel="canonical" href="http://www.mscnc.org/our-profile"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | Our Profile - M S CNC"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | Our Profile - M S CNC"/>
      <meta property="og:url" content="https://www.mscnc.org/our-profile"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering. Our promise of quality is the top priority.
          Customer care, competitive pricing, handling complex difficult projects is what we do. "/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1yY4bUF8gwuGzKq6bxE6VlDC1dCK2Akqr'/>
      <meta property="og:image:width" content="1043"/>
      <meta property="og:image:height" content="700"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | Our Profile - M S CNC"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1yY4bUF8gwuGzKq6bxE6VlDC1dCK2Akqr'/>
      <meta name="twitter:url" content="https://www.mscnc.org/our-profile"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering. Our promise of quality is the top priority.
          Customer care, competitive pricing, handling complex difficult projects is what we do. "/>
          </MetaTags>
      <Ourprofile />
      
    </Route>
    <Route exact path = '/our-beliefs'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Our Beliefs</title>
        <meta name="description" content="M S CNC offers Precision engineering and this is what we believe in. 
        Our Vision, Our Mission and our commitments to our customers, our employees and to our suppliers." />
        <link rel="canonical" href="http://www.mscnc.org/our-beliefs"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Our Beliefs"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Our Beliefs"/>
      <meta property="og:url" content="https://www.mscnc.org/our-beliefs"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and this is what we believe in. 
        Our Vision, Our Mission and our commitments to our customers, our employees and to our suppliers. "/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1yY4bUF8gwuGzKq6bxE6VlDC1dCK2Akqr'/>
      <meta property="og:image:width" content="1043"/>
      <meta property="og:image:height" content="700"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Our Beliefs"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1yY4bUF8gwuGzKq6bxE6VlDC1dCK2Akqr'/>
      <meta name="twitter:url" content="https://www.mscnc.org/our-beliefs"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and this is what we believe in. 
        Our Vision, Our Mission and our commitments to our customers, our employees and to our suppliers. "/>
          </MetaTags>
      <Ourbeliefs />
    </Route>
    <Route exact path = '/our-industry'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Our Industry</title>
        <meta name="description" content="M S CNC offers Precision engineering and is the backbone 
        that supports design, prototyping, production, such as aerospace, oil and gas globally." />
        <link rel="canonical" href="http://www.mscnc.org/our-industry"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Our Industry"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Our Industry"/>
      <meta property="og:url" content="https://www.mscnc.org/our-industry"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and is the backbone 
        that supports design, prototyping, production, such as aerospace, oil and gas globally. "/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1xLqN6Bq2ZBcHMddCwao0I1IrwG_uXxrp'/>
      <meta property="og:image:width" content="1043"/>
      <meta property="og:image:height" content="700"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Our Industry"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1xLqN6Bq2ZBcHMddCwao0I1IrwG_uXxrp'/>
      <meta name="twitter:url" content="https://www.mscnc.org/our-industry"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and is the backbone 
        that supports design, prototyping, production, such as aerospace, oil and gas globally. "/>
          </MetaTags>
      <Ourindustry />
    </Route>
    <Route exact path = '/pms'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Precision Machining</title>
        <meta name="description" content="M S CNC offers Precision engineering and has various precision machining 
        equipment such as CNC 3 + 2 axis, CNC 3 axis, CNC wire cut and laser engraving." />
        <link rel="canonical" href="http://www.mscnc.org/pms"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Precision Machining"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Precision Machining"/>
      <meta property="og:url" content="https://www.mscnc.org/pms"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and has various precision machining 
        equipment such as CNC 3 + 2 axis, CNC 3 axis, CNC wire cut and laser engraving. "/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1U47R3KPopZaWUaJ3iNvPv50GOq1B4p-a'/>
      <meta property="og:image:width" content="1043"/>
      <meta property="og:image:height" content="700"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Precision Machining"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1U47R3KPopZaWUaJ3iNvPv50GOq1B4p-a'/>
      <meta name="twitter:url" content="https://www.mscnc.org/pms"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and has various precision machining 
        equipment such as CNC 3 + 2 axis, CNC 3 axis, CNC wire cut and laser engraving."/>
          </MetaTags>
      <Pms />
    </Route>
    <Route exact path = '/emm'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC exotic materials</title>
        <meta name="description" content="M S CNC offers Precision engineering and can deal with complex, rare and
        exotic materials such as Stainless Steel (303, 304L), Aluminium Alloy (2014, 2618), Nickel Based Alloy,
        Titanium, Copper Alloy (Brass, Bronze, Aluminium bronze), Carbon/Alloy Steel, enginerring plastics
        such as derlin." />
        <link rel="canonical" href="http://www.mscnc.org/emm"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC exotic materials"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC exotic materials"/>
      <meta property="og:url" content="https://www.mscnc.org/emm"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and can deal with complex, rare and
        exotic materials such as Stainless Steel (303, 304L), Aluminium Alloy (2014, 2618), Nickel Based Alloy,
        Titanium, Copper Alloy (Brass, Bronze, Aluminium bronze), Carbon/Alloy Steel, enginerring plastics
        such as derlin."/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1giRCq7m6EDOH2-eNsrod9qrXFdkTML95'/>
      <meta property="og:image:width" content="600"/>
      <meta property="og:image:height" content="1043"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC exotic materials"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1giRCq7m6EDOH2-eNsrod9qrXFdkTML95'/>
      <meta name="twitter:url" content="https://www.mscnc.org/emm"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and can deal with complex, rare and
        exotic materials such as Stainless Steel (303, 304L), Aluminium Alloy (2014, 2618), Nickel Based Alloy,
        Titanium, Copper Alloy (Brass, Bronze, Aluminium bronze), Carbon/Alloy Steel, enginerring plastics
        such as derlin."/>
          </MetaTags>
      <Emm/>
    </Route>
    <Route exact path = '/occ'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC CAD capabilities</title>
        <meta name="description" content="M S CNC offers Precision engineering and uses high performance CAD software
        with up to 3 + 2 axis CNC machining." />
        <link rel="canonical" href="http://www.mscnc.org/occ"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC CAD capabilities"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC CAD capabilities"/>
      <meta property="og:url" content="https://www.mscnc.org/occ"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and uses high performance CAD software
        with up to 3 + 2 axis CNC machining."/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1zpIm7FeTrt41acdddkiMWgqEvRwzz4i8'/>
      <meta property="og:image:width" content="800"/>
      <meta property="og:image:height" content="800"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC CAD capabilities"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1zpIm7FeTrt41acdddkiMWgqEvRwzz4i8'/>
      <meta name="twitter:url" content="https://www.mscnc.org/occ"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and uses high performance CAD software
        with up to 3 + 2 axis CNC machining."/>
          </MetaTags>
      <Occ/>
    </Route>
    <Route exact path = '/opl'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC production layout</title>
        <meta name="description" content="M S CNC offers Precision engineering and this is our production layout.
        We have 3 3 + 2 CNC machines, 6 3 axis CNC machine, 1 wirecut machine and 2 CMM machines for quality control." />
        <link rel="canonical" href="http://www.mscnc.org/opl"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC production layout"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC production layout"/>
      <meta property="og:url" content="https://www.mscnc.org/opl"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and this is our production layout.
        We have 3 3 + 2 CNC machines, 6 3 axis CNC machine, 1 wirecut machine and 2 CMM machines for quality control."/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=19vKCXo72hRSww7J1pHfENikR8QXJ6EDd'/>
      <meta property="og:image:width" content="1000"/>
      <meta property="og:image:height" content="500"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC production layout"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=19vKCXo72hRSww7J1pHfENikR8QXJ6EDd'/>
      <meta name="twitter:url" content="https://www.mscnc.org/opl"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and this is our production layout.
        We have 3 3 + 2 CNC machines, 6 3 axis CNC machine, 1 wirecut machine and 2 CMM machines for quality control."/>
          </MetaTags>
      <Opl/>
    </Route>
    <Route exact path = '/newsandevents'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC news and events</title>
        <meta name="description" content="M S CNC offers Precision engineering and this is our latest news in action." />
        <link rel="canonical" href="http://www.mscnc.org/newsandevents"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC news and events"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC news and events"/>
      <meta property="og:url" content="https://www.mscnc.org/newsandevents"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and this is our latest news in action."/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1zpIm7FeTrt41acdddkiMWgqEvRwzz4i8'/>
      <meta property="og:image:width" content="800"/>
      <meta property="og:image:height" content="800"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC news and events"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1zpIm7FeTrt41acdddkiMWgqEvRwzz4i8'/>
      <meta name="twitter:url" content="https://www.mscnc.org/newsandevents"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and this is our latest news in action."/>
          </MetaTags>
      <Newsandevents/>
    </Route>
    <Route exact path = '/careers'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC careers</title>
        <meta name="description" content="M S CNC offers Precision engineering and this is our latest job openings. 
        Apply now and join our workforce today." />
        <link rel="canonical" href="http://www.mscnc.org/careers"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC careers"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC careers"/>
      <meta property="og:url" content="https://www.mscnc.org/careers"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and this is our latest job openings. 
        Apply now and join our workforce today."/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1zpIm7FeTrt41acdddkiMWgqEvRwzz4i8'/>
      <meta property="og:image:width" content="800"/>
      <meta property="og:image:height" content="800"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC careers"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1zpIm7FeTrt41acdddkiMWgqEvRwzz4i8'/>
      <meta name="twitter:url" content="https://www.mscnc.org/careers"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and this is our latest job openings. 
        Apply now and join our workforce today."/>
          </MetaTags>
      <Careers/>
    </Route>
    <Route exact path = '/contactus'>
    <Helmet>
      <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Contact Us</title>
        <meta name="description" content="M S CNC offers Precision engineering and this is our location
        and how to contact us. We are located at Yishun." />
        <link rel="canonical" href="http://www.mscnc.org/contactus"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Contact Us"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Contact Us"/>
      <meta property="og:url" content="https://www.mscnc.org/contactus"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and this is our location
        and how to contact us. We are located at Yishun."/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1yY4bUF8gwuGzKq6bxE6VlDC1dCK2Akqr'/>
      <meta property="og:image:width" content="1043"/>
      <meta property="og:image:height" content="700"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Contact Us"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1yY4bUF8gwuGzKq6bxE6VlDC1dCK2Akqr'/>
      <meta name="twitter:url" content="https://www.mscnc.org/contactus"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and this is our location
        and how to contact us. We are located at Yishun."/>
          </MetaTags>
      <Contactus/>
    </Route>
    <Route exact path = '/success'>
      <Success/>
    </Route>
    <Route exact path = '/qanda'>
      <Helmet>
        <title>CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Questions and Answers</title>
        <meta name="description" content="M S CNC offers Precision engineering and these are our frequently
        asked questions answered. We are located at Yishun." />
        <link rel="canonical" href="http://www.mscnc.org/qanda"></link>
      </Helmet>
      <MetaTags>
      <meta property="og:site_name" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Questions and Answers"/>
      <meta property="og:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Questions and Answers"/>
      <meta property="og:url" content="https://www.mscnc.org/contactus"/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content="M S CNC offers Precision engineering and these are our frequently
        asked questions answered. We are located at Yishun."/>
      <meta property="og:image" content='https://drive.google.com/uc?export=download&id=1yY4bUF8gwuGzKq6bxE6VlDC1dCK2Akqr'/>
      <meta property="og:image:width" content="1043"/>
      <meta property="og:image:height" content="700"/>
      <meta name="twitter:title" content="CNC Precision Engineering Parts Singapore | CNC Milling - M S CNC Questions and Answers"/>
      <meta name="twitter:image" content='https://drive.google.com/uc?export=download&id=1yY4bUF8gwuGzKq6bxE6VlDC1dCK2Akqr'/>
      <meta name="twitter:url" content="https://www.mscnc.org/contactus"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content="M S CNC offers Precision engineering and these are our frequently
        asked questions answered. We are located at Yishun."/>
          </MetaTags>
      <QandA/>
    </Route>
    <Route exactpath='*'>
        <NotFound />
      </Route>
    </Switch>
    <Footer /> 
    </ScrollToTop>   
 
    </Router>  
    


  );
}

export default App;
