import React from 'react';
import './emm.css';
import banner from '../../assests/banner.jpg'
import rawmaterial from '../../assests/rawmaterial.jpg';

const emm = () => {
  return (
    <div className='pmsmain'>
    <div className='pms'>
        <img src = {banner} alt = 'banner' className='banner' />       
    </div>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Exotic Materials Machining</h1>
     </div>
     <div className='pmsdescriptionbody'>
      <p className='pmsdescription'><em>M S CNC Manufacture Pte. Ltd. possesses the capability to machine a wide range of exotic materials that include:</em></p>
     </div>
     <div className='rawmaterialbody'>
        <img src = {rawmaterial} alt ="rawmaterial" className='rawmaterialpicture' />
     </div>
     <div className='rawmaterialslist'>
            <p><b>Metals: </b></p>
            <ul>
                <li className='metalslist'>Stainless Steel (303, 304L, 316L, 420, 440C, 630, etc)</li>
                <li className='metalslist'>Aluminium Alloy (2014,  2618, 5083, 6061, 6082, 7075, etc)</li>
                <li className='metalslist'>Nickel Based Alloy (Inconel 625, 718, Permalloy, Monel, Hastelloy)</li>
                <li className='metalslist'>Titanium</li>
                <li className='metalslist'>Copper Alloy (Brass, Bronze, Aluminium bronze)</li>
                <li className='metalslist'>Carbon/Alloy Steel </li>
                <li className='metalslist'>and more...</li>
            </ul>
            <p><b>Engineering Plastics: </b></p>
            <ul>
              <li className='metalslist'>Derlin</li>
              <li className='metalslist'>and more...</li>
            </ul>
     </div>
     </div>
  )
}

export default emm