import './Ourprofile.css';
import React from 'react'
import banner from '../../assests/About-Us-banner-gold-2.jpg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

const Ourprofile = () => {
  return (
    <div className='ourprofilemain1'>
    <div className='ourprofile'>
        <img src = {banner} alt = 'banner' className='banner' />       
    </div>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Our Profile</h1>
     </div>
     <div className='maindescriptionbody'>
      <p className='maindescription'>Established in 2002, M S CNC Manufacturing Pte. Ltd. relies on over 20 years
        of industry experience and knowledge to provide world class engineering solutions 
        to a wide variety of industries that include satellites, biomedical, oil & gas and 
        aerospace industries. The products and services we provide for the above-mentioned 
        industries include precision CNC-machined parts, production tooling and jigs & fixtures.
      </p>
     </div>
     <div className='grid-containerprofile'>
      <div className='valuesbody'>
      <div className='grid-containerquality'>
      <FontAwesomeIcon icon={faCheck}  size ='2x' className = 'checkicon'/>
      <h1 className='quality'>Our Promise of Quality</h1>
      </div>
      <div className='qualitydescription'>
      <p>Conformance to specification is critical in the precision engineering industry.
      This is why we have taken it upon ourselves to constantly maintain strict operational quality.</p>
      <p>We adopt a customer-oriented approach to our work. With state of the art technology and equipment, we consistently
        meet exceptional quality while complying with rigorous customer specifications in a wide variety of materials, including
        plastics. 
      </p>
      <p>Our business is ISO 9001 : 2015 certified and our parts pass through comprehensive inspections before delivery to our customers.</p>
      <p>With a proven track record and modern equipment housed in a state of the art plant, rest assured that we can convert your drawings 
        into reality. 
      </p>
      </div>
      </div>
     <div className='valuesbody2'>
      <div className='grid-containercare'>
     <FontAwesomeIcon icon={faCheck}  size ='2x' className = 'checkicon'/>
     <h1 className = 'customercareheading'>Customer Care:</h1>
     </div>
     <p className='customercaredescription'>At M S CNC Manufacture Pte. Ltd., our customers come first. Through good engineering practices, we can manages our costs and these savings
      are passed on to our customers.
     </p>
     <div className='grid-containerpricing'>
     <FontAwesomeIcon icon={faCheck}  size ='2x' className = 'checkicon'/>
     <h1 className='competitivepricingheading'>Competitive Pricing:</h1>
     </div>
     <p className='competitivepricingdescription'>With our customer-oriented approach, we employ many cost efficient processes to ensure the highest quality yet at a fraction of its cost. </p>
     <div className='grid-containerpeople'>
     <FontAwesomeIcon icon={faCheck}  size ='2x' className = 'checkicon'/>
     <h1 className='peopleheading'>People:</h1>
     </div>
     <p className='peopledescription'>Our people are trained in a variety of softwares to handle the most demanding projects.</p>
     </div>     
     </div>
     </div>
  )
}

export default Ourprofile