import './Ourbeliefs.css';
import React from 'react'
import banner from '../../assests/About-Us-banner-gold-2.jpg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
const Ourbeliefs = () => {
  return (
    <div className='ourprofilemain'>
      <Helmet>
        <title>Our Beliefs</title>
        <meta name="description" content="CNC manufacturing manufacture beliefs values" />
    </Helmet>
    <div className='ourprofile'>
        <img src = {banner} alt = 'banner' className='banner' />       
    </div>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Our Beliefs</h1>
     </div>
     <div className='ourbeliefsbody'>
      <div className='ourbeliefspage1'>
      <div className='grid-containervision'>
     <FontAwesomeIcon icon={faCheck}  size ='2x' className = 'checkicon'/>
     <h1 className='ourvisionheading'>Our Vision</h1>
     </div>
     <p className='ourvisiondescription'>M S CNC Manufacture Pte. Ltd. aims to be the preferred one-stop supplier in total 
     precision engineering solutions.</p>
    
     <div className='grid-containermission'>
     <FontAwesomeIcon icon={faCheck}  size ='2x' className = 'checkicon'/>
     <h1 className='ourmissionheading'>Our Mission</h1>
     </div>
     <p className='ourmissiondescription'>To meet the needs of our customers<br></br>
     To build a knowledgeable and efficient workforce<br></br>
     To forge strategic partnerships with our suplliers, developing long term relationships of respect and trust.</p>
     </div>
     <div className='ourbeliefs2'>
      <div className='grid-containercommitments'>
     <FontAwesomeIcon icon={faCheck}  size ='2x' className = 'checkicon'/>
     <h1 className='ourcommitmentsheading'>Our Commitments</h1>
     </div>
     <p className='ourcommitmentsdescriptioncustomer'><b>To Our Customers</b> <em>We strive to perform beyond our customers' requirements and expectations, by continuously
     maintaining high quality in every aspect of the manufacturing process.</em></p>
     <p className='ourcommitmentsdescription'><b>To Our Employees</b> <em>Our employees are the driving force behind the company. We respect each individual and strive to make
        the workplace a conducive environment, aiming to produce a highly motivated team that provides the best engineering solutions for our customers.</em></p>
     <p className='oursuppliersdescription'><b>To Our Suppliers</b> <em>We collaborate with our suppliers to forge a strategic alliance that will provide best in class service 
        to all our customers, built upon values of respect and trust.</em></p>
     </div>
     </div>
    </div>
  )
}

export default Ourbeliefs