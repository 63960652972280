import {useFormik } from 'formik'
import React, {useState} from 'react'
import './Form.css';
import { basicSchema } from './schemas';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

const sendDataToEmailApi = (values) => {
    // call your email api with the values
    emailjs.send("service_q713g2f","template_tguhvtc",values,"klEx-zVNV4jN_OpuO");
    setTimeout(redirect,2000);
    return true; // to show the send process was successful
  };
function redirect() {
  window.location.replace('/success')
}
  
const onSubmit = (values, { setSubmitting, resetForm, isSubmitting }) => {
    const emailWasSent = sendDataToEmailApi(values);
    if (emailWasSent) {
      resetForm();
      setSubmitting(false);
      
    }
    
  };


const Form = () => {
  const [verified, setVerified] = useState(false);
    const {values, errors, touched, isSubmitting, handleChange, handleSubmit} = useFormik({
        initialValues: {
            name: "",
            companyname: "",
            email: "",
            contactnumber: "",
            message: "",
        },
        validationSchema: basicSchema,
        onSubmit
    });
    
    function onChange(value) {
      setVerified(true);   
         
    }
  return (
    <form onSubmit = {handleSubmit} autoComplete='off' action = '/success'>
        <label htmlFor='name'>Name</label>
        <input 
        value = {values.name}
        onChange = {handleChange}
        id="name"
        type="name"
        placeholder='Enter your name'
        className={errors.name && touched.name? "input-error" : ""}
        />
        {errors.name && touched.name && <p className='error'>{errors.name}</p>}
         <label htmlFor='companyname'>Company Name/Organisation</label>
        <input 
        value = {values.companyname}
        onChange = {handleChange}
        id="companyname"
        type="companyname"
        placeholder='Enter your companyname/organisation'
        className={errors.companyname && touched.companyname ? "input-error" : ""}
        />
        {errors.companyname && touched.companyname && <p className='error'>{errors.companyname}</p>}
         <label htmlFor='email'>Email</label>
        <input 
        value = {values.email}
        onChange = {handleChange}
        id="email"
        type="email"
        placeholder='Enter your email'
        className={errors.email && touched.email ? "input-error" : ""}
        />
        {errors.email && touched.email && <p className='error'>{errors.email}</p>}
        <label htmlFor='contactnumber'>Contact Number</label>
        <input 
        value = {values.contactnumber}
        onChange = {handleChange}
        id="contactnumber"
        type="contactnumber"
        placeholder='Enter your contact number'
        className={errors.contactnumber && touched.contactnumber ? "input-error" : ""}
        />
        {errors.contactnumber && touched.contactnumber && <p className='error'>{errors.contactnumber}</p>}
        <label htmlFor='message'>Message</label>
        <textarea
        value = {values.message}
        onChange = {handleChange}
        id = 'message'
        type = 'message'
        row='3'
        placeholder='Enter your message'
        className={errors.message && touched.message ? "input-error" : ""}
        />
        {errors.message && touched.message && <p className = 'error'>{errors.message}</p>}
        <ReCAPTCHA
        sitekey='6Leg_jImAAAAAL6XpFsxe5LTR8US_749J_zJHJyE'
         onChange = {onChange}
        />
          <button disabled = {isSubmitting || !verified} type = 'submit'>Submit</button>
    </form>
  );
};

export default Form;