import './Newsandevents.css';
import React from 'react';
import interview from '../../assests/CADpicture.jpg';

const Newsandevents = () => {
  return (
    <div className='newsandeventsmain'>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>News and Events</h1>
     </div>   
     <img src = {interview} alt = "interview" className='interviewimg'/>  
     <h1 className='news1heading'>Passion to tinkle with the latest software developments.</h1>
     <p className='news1description'>“I enjoy trying out the latest NX software in my product development process as our team of engineers can test out and keep abreast in the latest changes in design tools and applications.
      Technology is changing at such a rapid pace now. As a small outfit, we can be flexible to adopt new changes quickly to meet the demands of the Precision Engineering sector. Investing in workers’ skills upgrading and 
      training is a critical component in maintaining our competitive edge in the business. It is tough to work in this environment which prevents a lot of people from venturing into the manufacturing industry. However I 
      believe that once they are able to pick up the skills and knowledge, they will be proud of their work. In addition, there is a need to embrace change and constantly look to improving the technology and talent to stay ahead.” Ma Zixue (Managing Director of M S CNC MANUFACTURE PTE. LTD.
      </p>
      <a href = "https://m.facebook.com/EmploymentAndEmployabilityInstitute/posts/passion-to-keep-up-and-tinkle-with-the-latest-software-development-in-the-rapidl/1617254435033903/#_=_" className='readmorebutton'>
        Read More
      </a>
     </div>
  )
}

export default Newsandevents