import './QandA.css';
import React from 'react'

const QandA = () => {
  return (
    <div className='pmsmain'>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Frequently asked questions</h1>
     </div>   
     <div className='qandamain'>
     <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOne">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        My project is very complex and requires attention to details. Will you be able to handle?
      </button>
    </h2>
    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">No project is too big or complex for us. We prioritize in customer satsifaction and ensure our products meet your stringest needs and checks. We have in place many checks to ensure that the products delivered to you are of a very high quality. Contact us at mscnc@singnet.com.sg for a non-obligatory quote today!</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
        My Company is not located in Singapore, will you be able to serve us?
      </button>
    </h2>
    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Yes, we do have customers from the United States and other countries. Do reach out to us to see how your project can be realised!</div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
        My Company has stringent requirements for our partners, are you open to site visits?
      </button>
    </h2>
    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div className="accordion-body">Yes, our company is open for site visits and we are an ISO 9001 certified company. Do reach out to us to schedule a site visit!</div>
    </div>
  </div>
</div>
<h5>Did not answer your questions?</h5>
<a href = '/contactus' className='qandaA'><button id = 'qandacontactus'>Contact Us</button></a>
     </div>
     </div>
  )
}

export default QandA