import './Opl.css';
import banner from '../../assests/banner.jpg';
import React from 'react';
import layout from '../../assests/Layout.png';

const Opl = () => {
  return (
    <div className='pmsmain'>
    <div className='pms'>
        <img src = {banner} alt = 'banner' className='banner' />       
    </div>
    <div className='ourprofileheading1'>
     <h1 className='ourprofileheading'>Our Production Layout</h1>
     </div>
     <div className='pmsdescriptionbody'>
      <p className='pmsdescription'><em>M S CNC Manufacture has invested in a wide range of advanced equipment to facilitate the differing
        machining needs of our customers.</em></p>
     </div>
     <img src = {layout} alt = 'layout' className='layoutpic'/>
     
     </div>
  )
}

export default Opl